// 时间周期
const TimeUnit = {
  // 天
  DAY: 1,
  // 周
  WEEK: 2,
  // 月
  MONTH: 3,
  // 季
  QUARTERLY: 4,
  // 年
  YEAR: 5,
};
// 合同类型
const ContractType = {
  // 创建合同
  CREATE: 1,
  // 续期合同
  PRE_RENEW: 2,
  // 变更合同
  PRE_UPDATE: 3,
};

// 支付状态
const BillState = {
  // 未支付（未通知）
  UNPAID: 1,
  // 未支付（已通知）
  UNPAID_NOTIFY: 2,
  PAID: 3,
  INVALIDITY: 4,
};
// 支付渠道
const PAY_CHANNEL = {
  // 现金支付
  CASH_PAY: 1,
  // 银行转账
  BANK_PAY: 2,
  // 支付宝支付
  ALI_PAY: 3,
  // 微信支付
  WE_CHAT_PAY: 4,
};
// 上传房间照片类型
const UploadRoomPhotoType = {
  BEFORE: 1,
  AFTER: 2,
};
// 房间出租状态
const RoomTentStatus = {
  ALL: -1,
  RENT: 1,
  NO_RENT: 2,
};
// 小红点查询的类型
const RED_DOT_TYPES = {
  ENT_INQUIRY: "ent.inquiry",
  ENT_BILL_PAYMENT: "ent.billpayment",
  ENT_BILL_REFUND: "ent.billrefund",
  TIC_BILL_PAYMENT: "tic.billpayment",
  TIC_BILL_REFUND: "tic.billrefund",
  SOC_SENSITIVE: "soc.sensitive",
};

const SOCKET_STATUS = {
  ERROR: 500,
}

export {
  TimeUnit,
  ContractType,
  BillState,
  PAY_CHANNEL,
  UploadRoomPhotoType,
  RoomTentStatus,
  RED_DOT_TYPES,
};

<template>
  <div id="RoomInfo">
    <CSBreadcrumb/>
    <div class="result-panel">
        <CSTable>
            <template v-slot:thead>
                <tr>
                    <th>合同编号</th>
                    <th>合同时间</th>
                    <th>租客</th>
                </tr>
            </template>
            <template v-slot:tbody>
                <tr
                    v-for="contract in contractList"
                    :key="contract.contractId"
                >
                    <td v-if="contract.contractNo">
                        <span @click="pactDetails(contract.contractId)" class="allow-click">{{contract.contractNo  }}</span>
                    </td>
                    <td v-else>
                        <span>-</span>
                    </td>
                    <td>{{contract.contractDuration || '-'}}</td>
                    <td>
                        <p>{{ contract.lesseeCompany}}</p>
                        <p>
                            {{ contract.lesseeName }}
                            ({{ contract.lesseePhone }})
                        </p>
                    </td>
                </tr>
            </template>
        </CSTable>
    </div>
    <div>
      <!-- 合同详情 -->
      <el-drawer
          title=""
          size="800px"
          :visible.sync="drawer"
          direction="rtl"
      >
        <ContractPreview style="height: 100%" :contract-info="contractInfo" />
      </el-drawer>
      <view-modal />
	</div>
  </div>
</template>

<script>
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import {
  roomStateDetailUrl, pactDetailsUrl,
} from "@/requestUrl";
import ContractPreview from "@/components/ContractPreview";
import ViewModal from "@/components/ViewModal";
import CSTable from "@/components/common/CSTable";

export default {
  name: "RoomInfo",
  props: {
    roomId: Number,
  },
  components: {
      CSTable,
    ViewModal,
    ContractPreview,
    CSBreadcrumb,
  },
  data() {
    return {
	// 控制抽题显示与隐藏的
	drawer: false,
	// 获取到的合同数据
	contractInfo: {
		// 分期的数据
		installments: [],
		otherCharges: [],
	},
      contractList: [],
    }
  },
  created() {
    this.queryRoomDetail();
  },
  methods: {
	// 获取合同详情信息
	pactDetails(contractId) {
		this.$fly
			.post(pactDetailsUrl, {
				contractId,
				operatorId: this.$vc.getCurrentStaffInfo().userId,
			})
			.then((res) => {
				this.contractInfo = res.data;
				this.drawer = true;
			});
	},
    // 获取组控图详情信息
    queryRoomDetail() {
      this.$fly
        .post(roomStateDetailUrl, {
            regionId: this.$vc.getCurrentRegion().communityId,
            roomId: this.roomId,
            operatorId: this.$vc.getCurrentStaffInfo().userId,
        })
        .then(res=>{
          if (res.code != 0) {
            return;
          }
          this.contractList = res.data;
        })
    },
  }
}

</script>

<style lang="stylus" scoped>
	.pactbox {
		width: 1000px;
		height: 100vh;
		background-color: #ffffff;
		margin-left: 10px;
		/* box-sizing: border-box; */
		overflow-y: auto;
	}
	.pactbox h3 {
		width: 95%;
		font-size: 24px;
		margin: 20px 45px;
		font-weight: normal;
	}
	i {
		font-size: 20px;
	}
	.pactbox1 {
		width: 95%;
		margin: 0 auto;
		border-bottom: 1px #dddddd solid;
		border-top: 1px #dddddd solid;
	}
	.pactbox1 span {
		margin-left: 60px;
	}
	.pactbox2,
	.pactbox3,
	.pactbox4 {
		width: 95%;
		margin: 10px auto;
		border-bottom: 1px #dddddd solid;
		overflow: hidden;
	}
	.pactbox2 span {
		margin-left: 60px;
	}

	.pactid {
		display: block;
		padding: 20px 0 0 40px;
	}
	.leftback {
		margin-left: 40px;
		margin-top: 10px;
	}
	.leftback2 {
		margin-left: 40px;
		display: inline-block;
		vertical-align: top;
		font-size: 20px;
	}
	.pactsum {
		display: inline-block;
		font-size: 20px;
		vertical-align: top;
	}
	.pactsumRenter {
		display: inline-block;
		font-size: 20px;
		vertical-align: top;
	}

	.pactsum1 {
		display: inline-block;
		margin-left: 50px;
	}
	.toptback {
		margin: 20px 0 0 40px;
	}
	.el-drawer span {
		font-size: 20px;
	}
	.paytime {
		margin: 0 20px 0 60px;
	}
	.el-drawer input {
		margin: 0 8px;
	}
	.propertyfee {
		margin-left: 65px;
		vertical-align: top;
	}
	.paydate {
		margin-left: 40px;
		margin-right: 20px;
		margin-bottom: 20px;
	}
	body {
    background-color: #ffffff;
}
i {
    font-style: normal;
}

.query {
    margin-left: 10px;
    background-color: #1ab394;
    color: #ffffff;
}
.nav_1 {
    font-size: 24px;
    margin: 20px 50px 20px 0;
}
.navBar {
    width: 150px;
    height: 40px;
    color: #ffffff;
    border: 0;
}
/* 房间信息 */
.box {
    display: inline-block;
    width: 270px;
    background-color: #1ab394;
    border-radius: 5px;
    color: #ffffff;
    padding: 10px;
    overflow: hidden;
    margin: 5px;
}

.box p {
    margin-bottom: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.box p:not(:last-of-type) {
    margin-bottom: 10px;
}

.message_ {
    display: block;
    margin-top: 10px;
}
.orgin {
    background-color: #ff9f00;
}
.el-card {
    margin: 20px 0;
}
.color_1 {
    background-color: #6c757e;
    border-left: 0;
}
.color {
    background-color: #1ab394;
}

/* 表格区域 */

.shut {
    margin-right: 250px;
}

/* 合同内容 */
.leftpactmatter {
    margin: 10px 0 0 100px;
}

/* 照片div */
.photo {
    display: inline-block;
    margin-left: 65px;
}
.pactbox4 p {
    font-size: 20px;
    margin-left: 60px;
    /* display: inline-block; */
}
.leftpactmatter1 {
    vertical-align: top;
    margin: 20px 0 0 80px;
}
.leftpactmatter2 {
    vertical-align: top;
    margin: 10px 0 0 100px;
}

.remarks {
    margin-left: 60px;
}
.usednumber {
    margin-left: 40px;
}
.el-upload {
    text-align: center;
}

.affix {
    display: inline-block;
    margin-left: 60px;
}
.affix span {
    color: gray;
    font-size: 18px;
}
a {
    text-decoration: underline;
    margin-right: 30px;
    font-size: 20px;
    color: #2cb4ff;
}
.compact {
    width: 1005px;
    height: 1000px;
}
.rightback {
    margin-right: 20px;
}

.son1,
.son2 {
    display: inline-block;
}
.son1 {
    vertical-align: top;
    margin-right: 60px;
}
.runmp {
    width: 100%;
    height: 200px;
    background-color: red;
}

.keydeposit {
    display: inline-block;
    margin-left: 20px;
    margin-right: 20px;
    height: 100%;
}
.block_ {
    /* display: inline-block; */
    float: left;
    margin-bottom: 20px;
    text-align: right;
}

.block_1 {
    /* float: left; */
    display: inline-block;
}
img {
    width: 80px;
    height: 80px;
    margin: 5px 10px;
}
.keepleft {
    display: inline-block;
    font-size: 20px;
    padding-left: 20px;
}
.keepright {
    position: relative;
    top: -30px;
    display: inline-block;
    margin-left: 120px;
}
</style>
